<template>
  <Announcement
    :type="type"
    :title="title"
    :description="description"
    :button-text="buttonText"
    :button-href="buttonHref"
    :color="color"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      type() {
        return this.getValue("type")
      },
      title() {
        return this.getValue("title")
      },
      description() {
        return this.getRichtext("description")
      },
      buttonText() {
        return this.getValue("button_text")
      },
      buttonHref() {
        return this.getIngredient("button_text")?.linkUrl
      },
      color() {
        return this.getValue("color")
      },
    },
  }
</script>

<template>
  <Container space-before space-after>
    <div :class="['announcement', 'announcement-color-' + color]">
      <span class="announcement-title">{{ type }}</span>
      <Headline :level="2" :size="3" no-margin>
        {{ title }}
      </Headline>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="announcement-text" v-html="description" />
      <NuxtLink :to="buttonHref">{{ buttonText }}</NuxtLink>
    </div>
  </Container>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      buttonText: {
        type: String,
        default: null,
      },
      buttonHref: {
        type: String,
        default: null,
      },
      color: {
        type: String,
        default: "blue",
        validator(value) {
          return ["blue", "navy", "onyx", "sand"].includes(value)
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  .announcement {
    padding: $space-m;
    border-radius: $border-radius;

    &-color-sand {
      background: $sand-tint;
    }

    &-color-navy {
      color: white;
      background: $navy;
    }

    &-color-blue {
      background: $blue-tint;
    }

    &-color-onyx {
      color: white;
      background: $onyx;
    }

    @include viewport("mini", "sm", "m") {
      padding: $base-spacing * 6;
    }

    a {
      color: inherit;
    }
  }
  .announcement-title {
    @apply text-sm leading-normal;
  }
  .announcement-text {
    max-width: $base-spacing * 130;
    margin-bottom: $space-s;
  }
</style>
